<!-- 头条资讯--定制内容 -->
<template>
  <div class="headline-madetxt">
    <h6 class="h6">原创插画报刊漫画嘻哈摇滚撞色国际音乐节海报</h6>
    <div class="info">
      <p>
        <span>作者：JJMIN</span>
        <span><svg><use xlink:href="#iconyanjing"></use></svg>297</span>
        <span>2019-09-25</span>
      </p>
    </div>
    <div class="html" v-html='htmls'></div>
    <div class="tips">
      <h6 class="h5">文章标签：</h6>
      <button v-for='item,index in tips' :key='index'>{{item.tit}}</button>
    </div>
    <div class="shire">
      分享：
      <button><svg><use xlink:href="#iconkongjian"></use></svg></button>
      <button><svg><use xlink:href="#iconweixin1"></use></svg></button>
      <button><svg><use xlink:href="#iconweibo"></use></svg></button>
    </div>
    <div class="othertxt">
      <p class="lt">上一篇：<button>《微微定包装盒设计，这几款可以选择》</button></p>
      <p class="rt">下一篇：<button>《微微定线下销售签约仪式》</button></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-madetxt',
  props: {
    msg: String
  },
  data(){
    return {
      htmls:'<img src="http://www.weidingwang.com/images/c3/4e/54/a1b434feeebb3bccb7478ffd96db648c2a731d3e.jpg">',
      tips:[
        {tit:'纸巾定制'},{tit:'N折手擦纸'},{tit:'广告纸巾'},{tit:'散装方巾'},{tit:'荷包纸巾'},{tit:'纸巾定制'},{tit:'大卷纸'},{tit:'手帕纸巾'},
        {tit:'纸巾定制'},{tit:'N折手擦纸'},{tit:'广告纸巾'},{tit:'散装方巾'},{tit:'荷包纸巾'},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-madetxt {
  width: 760px;margin:0 auto;background: #fff;padding:30px 20px 10px 20px;
  .h6 {
    font-size: 20px;
  }
  .info {
    p {
      color:#999;margin-top:10px;
      span {
        font-size: 14px;
        &:nth-child(2) {
          margin-left:30px;
          svg {
            fill: #999;width: 18px;height: 18px;vertical-align: -4px;margin-right: 5px;
          }
        }
        &:nth-child(3) {
          margin-left:20px;
        }
      }
    }
  }
  .html {
    margin-top:20px;
    /deep/ img {
      display: block;max-width: 760px;min-height: 760px;
    }
  }
  .tips {
    margin-top:30px;
    .h5 {
      font-size: 16px;font-weight: normal;color:#333;margin-bottom:15px;
    }
    button {
      background: none;padding:5px 10px;font-size: 14px;color:#888;border:1px solid #333;border-radius: 20px;margin-right: 10px;margin-bottom:10px;
    }
  }
  .shire {
    height: 40px;line-height: 40px;margin-top:10px;
    button {
      background: none;width: 25px;height: 25px;margin-right: 10px;vertical-align: -7px;
      svg {
        width: 25px;height: 25px;
      }
    }
  }
  .othertxt {
    margin-top:20px;
    .lt {
      float:left;font-size: 14px;
      button {
        background: none;color:#4b9ddd;
      }
    }
    .rt {
      float:right;font-size: 14px;
      button {
        background: none;color:#4b9ddd;
      }
    }
  }
}
</style>
