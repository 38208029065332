<!-- 头条资讯--案例展示 -->
<template>
  <div class="headline-ind">
    <h6 class="h5">更多案例展示</h6>
    <div class="list">
      <h6 class="h6">餐饮行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">美容保健行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">娱乐行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">教育行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">零售行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">服务行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">金融行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
    <div class="list">
      <h6 class="h6">其他行业</h6>
      <div class="center">
        <div class="img" v-for='item,index in list' :key='index'>
          <img :src="item.url">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headline-ind',
  props: {
    msg: String
  },
  data(){
    return {
      list:[
        {url:require('../../assets/img/headline/more.jpg')},
        {url:require('../../assets/img/headline/more.jpg')},
        {url:require('../../assets/img/headline/more.jpg')},
        {url:require('../../assets/img/headline/more.jpg')},
        {url:require('../../assets/img/headline/more.jpg')},
      ]
    }
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.headline-ind {
  width: 1200px;margin:0 auto;
  .h5 {
    font-size: 20px;text-align: center;height: 60px;line-height: 60px;
  }
  .list {
    background: #fff;padding:20px;
    .h6 {
      font-size: 20px;position: relative;
      &:after {
        content: "";display: block;width: 60px;height: 2px;background: #ec979c;position: absolute;bottom:-15px;left:0px;
      }
    }
    .center {
      margin-top:35px;zoom:1;
      .img {
        float:left;margin-right: 15px;
        img {
          display: block;border-radius: 10px;width: 220px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
      &:after {
        content: "";display: block;clear:both;
      }
    }
  }
}
</style>
